import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import AboutYzy from '../views/aboutYzy/aboutYzy.vue'
import LeaderDtl from '../views/aboutYzy/hospitalIntro/leaderDtl/index.vue'
import HonorDtl from '../views/aboutYzy/hospitalIntro/honorDtl/index.vue'
import YzySystem from '../views/yzySyten/yzySystem.vue'
import Experts from '../views/experts/experts.vue'
import ExpertInfo from '../views/experts/expertInfo/index.vue'
import NewsCenter from '../views/newsCenter/newsCenter.vue'
import NewsDtl from '../views/newsCenter/newsDtl/index.vue'
import NewsList from '../views/newsCenter/newsList/index.vue'
import PersonalMatters from '../views/personnelMatters/personnelMatters.vue'
import PatientServe from '../views/patientServe/index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Home',
	component: Home
}, {
	path: '/aboutYzy',
	name: 'AboutYzy',
	component: AboutYzy
}, {
	path: '/leaderDtl/:id',
	name: 'LeaderDtl',
	component: LeaderDtl
}, {
	path: '/honorDtl/:id',
	name: 'HonorDtl',
	component: HonorDtl
}, {
	path: '/yzySystem',
	name: 'YzySystem',
	component: YzySystem
}, {
	path: '/experts',
	name: 'Experts',
	component: Experts
}, {
	path: '/expertInfo',
	name: 'ExpertInfo',
	component: ExpertInfo
}, {
	path: '/newsCenter',
	name: 'NewsCenter',
	component: NewsCenter
}, {
	path: '/newsDtl',
	name: 'NewsDtl',
	component: NewsDtl
}, {
	path: '/newsList',
	name: 'NewsList',
	component: NewsList
}, {
	path: '/personalMatters',
	name: 'PersonalMatters',
	component: PersonalMatters
}, {
	path: '/patientServe',
	name: 'PatientServe',
	component: PatientServe
}]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior: () => ({
		y: 0
	})
})

export default router