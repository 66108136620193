<template>
  <div class="app-container">
    <!-- <div class="online">
      <el-divider content-position="left">
        <div class="hospital-into-title">
          {{data.noticeTitle || ""}}
        </div>
      </el-divider>
    </div> -->
    <div>
      <el-empty v-if="!data" :image-size="50">
      </el-empty>
      <div v-else style="margin-top: 20px;">
        <div v-html="data.noticeContent" class="content-common-content"></div>
        <div style="color: #9d9d9d;padding: 20px 0;">
          时间：{{ data.updateTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        noticeTitle: "",
        noticeContent: "",
        updateTime: ""
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$axios.get(this.$https + `user/protocol?noticeType=14&noticeAppType=5`).then(res => {
        if (res.data.data) {
          this.data = res.data.data || ""
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.app-container {
  min-height: 30vh;
}

.online {
  margin-top: 32px;
}

.hospital-into-title {
  font-size: 20px;
  font-weight: bold;
}

.content {
  min-height: 200px;
}
</style>
