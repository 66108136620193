<template>
  <div id="app">
    <!-- 头部导航-->
    <div class="header-container">
      <div class="header-content">
        <div class="logo-container" @click="handleLogeText">
          <a href="">
            <el-image :src="logoImage" class="logo-image"></el-image>
          </a>
          <div v-if="!seen" class="header-middle-title">
            传承精华 守正创新
          </div>
          <div v-if="!seen" style="color: #d71618;font-weight: bold;">
            <div>
              成都锦欣生殖专科联盟成员
            </div>
            <div>
              贵州中医药大学 实训基地、医联体成员
            </div>
          </div>
        </div>
        <!-- <div class="menu" v-if="seen">
          <el-image :src="menuImage" class="menu-image" v-if="menuShow" @click="handleMenu"></el-image>
          <el-image :src="menuImageClose" class="menu-image" v-if="menuClose"
            @click="handleMenuClose"></el-image>
        </div>
        <device-menu v-if="menuClose" :menuList="menuList"></device-menu> -->
      </div>
    </div>
    <div class="navigate">
      <div class="navigate-container">
        <div class="navigate-text" v-for="(item,index) in menuList" @click="handleSelect(item.path)">
          <a href="">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="!seen && storageLeft"
         style="position: absolute;left: 30px;top: 200px;display: flex;flex-direction: column;align-items: center;background-color: rgba(212,106,74,0.9);padding: 10px;border-radius: 8px;">
      <div style="font-weight: bold;color: #fff;">
        微信扫一扫
      </div>
      <div style="margin: 32px 0 10px 0;color: #fff;">
        扫码咨询
      </div>
      <el-image :src="appletImage" class="foot-applet-image"></el-image>
      <div style="margin:10px 0;color: #fff;">
        关注公众号
      </div>
      <el-image :src="officialAccountImage" class="foot-applet-image"></el-image>
      <div @click="handleLeftCodeClose"
           style="background-color: #fff;border-radius: 50%;width: 30px;height: 30px;font-size: 30px;margin-top: 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;letter-spacing:0px;">
        ×
      </div>
    </div>
    <div v-if="!seen && storageRigth"
         style="position: absolute;right: 30px;top: 200px;display: flex;flex-direction: column;align-items: center;background-color: rgba(212,106,74,0.9);padding: 10px;border-radius: 8px;">
      <div style="font-weight: bold;color: #fff;">
        微信扫一扫
      </div>
      <div style="margin: 32px 0 10px 0;color: #fff;">
        医生入驻
      </div>
      <el-image :src="dcotorJoinImage" class="foot-applet-image"></el-image>
      <div style="margin:10px 0;color: #fff;">
        云中医院商城
      </div>
      <el-image :src="yzyStore" class="foot-applet-image"></el-image>
      <div @click="handleRigthCodeClose"
           style="background-color: #fff;border-radius: 50%;width: 30px;height: 30px;font-size: 30px;margin-top: 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;letter-spacing:0px;">
        ×
      </div>
    </div>
    <router-view/>
    <div class="foot-container">
      <div class="foot-header">
        <div>友情链接：</div>
        <a href="http://www.nhc.gov.cn/" target="_blank">国家卫健委</a>
        <a href="http://www.gzhfpc.gov.cn/" target="_blank">贵州省卫生健康委</a>
        <a href="https://www.cma.org.cn/" target="_blank">中华医学会</a>
        <a href="https://www.gz5055.com/" target="_blank">贵州省人民医院</a>
      </div>
      <div style="height: 1px;background-color: #ffffff"></div>
      <div class="foot-body">
        <div>
          <div class="foot-logo-container" v-if="!seen">
            <el-image :src="logoImage" class="logo-image foot-logo-image"></el-image>
          </div>
          <div @click="handleAddress" style="cursor: pointer;">
            地址：贵州省贵阳市观山湖区金阳街道办事处华润国际社区C4栋
          </div>
          <div>
            咨询电话：
          </div>
          <div class="number-tel" @click="handleCall('0851-86868111')">
            0851-86868111（每天上午8:30—12:00，下午14:00—17:30，夜间18:00-21:00）
          </div>
        </div>
        <div class="foot-body-number-info">
          <div style="margin-top: 32px">
            投诉电话：
          </div>
          <div class="number-tel" @click="handleCall('0851-88417711')">
            0851-88417711
          </div>
          <div>
            上级部门投诉电话：
          </div>
          <div class="number-tel" @click="handleCall('0851-86837337')">
            0851-86837337（省卫键委医政医管处）
          </div>
          <a href="" v-show="false" ref="tels"></a> <!-- 多加一个a标签拉起打电话 -->
        </div>
        <div class="foot-applet-container" v-if="seen">
          <div class="foot-applet-doctor">
            <el-image :src="appletImage" class="foot-applet-image"></el-image>
            <div class="docotor-join-text" @click="handleDoctorJoin">
              扫码咨询
            </div>
          </div>
          <div class="foot-applet-patient">
            <el-image :src="dcotorJoinImage" class="foot-applet-image"></el-image>
            <div class="docotor-join-text" @click="handleDoctorJoin">
              医生入驻
            </div>
          </div>
          <div class="foot-applet-patient">
            <el-image :src="officialAccountImage" class="foot-applet-image"></el-image>
            <div>
              关注公众号
            </div>
          </div>
          <div class="foot-applet-patient">
            <el-image :src="yzyStore" class="foot-applet-image"></el-image>
            <div>
              云中医院商城
            </div>
          </div>
        </div>
      </div>
      <div class="foot-footer">
        <div class="foot-footer-content">
          <div>
            <div @click="handleAbout">
              <a href="">
                关于我们
              </a>
            </div>
            <div style="padding: 0 15px">|</div>
            <div @click="handleAddress">
              <a href="">
                医院地址
              </a>
            </div>
            <div style="padding: 0 15px">|</div>
            <div @click="handleCopyRigth">
              <a href="">
                版权声明
              </a>
            </div>
          </div>
          <div>
            <div>
              版权所有：贵州云中医院有限公司
            </div>
            <div>
              <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">黔ICP备16008087号-3</a>
            </div>
            <div>
							<span>
								<a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011502002257"
                   target="_blank">
									省公网备：贵公网安备52011502002257
								</a>
							</span>
              <span>

							</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="./assets/backtop_img.png" style="height: 45px;width: 35px"/>
    </div>
  </div>
</template>

<script>
import logoImage from '@/assets/yzylogo_1.png'
// import subLogoImage from '@/assets/sub_logo.png'
import menuImage from '@/assets/menuBtn.png'
import menuImageClose from '@/assets/menuclose.png'
import appletImage from '@/assets/applet.png'
import dcotorJoinImage from '@/assets/invite_doctor_code.png'
import officialAccountImage from '@/assets/official_account.jpg'
import yzyStore from '@/assets/yzy_store.png'
import DeviceMenu from '@/views/deviceMenu/index.vue'

export default {
  components: {
    DeviceMenu
  },
  data() {
    return {
      logoImage,
      // subLogoImage,
      menuImage,
      menuImageClose,
      // 云中医平台
      appletImage,
      // 商城
      yzyStore,
      // 医生入驻
      dcotorJoinImage,
      // 公众号
      officialAccountImage,
      menuList: [{
        name: '网站首页',
        path: 'Home'
      }, {
        name: '医院概况',
        path: 'About'
      },
        // {
        // 	name: '医院系统',
        // 	path: 'System'
        // },
        {
          name: '专家介绍',
          path: 'Experts'
        }, {
          name: '新闻中心',
          path: 'News'
        }, {
          name: '患者服务',
          path: 'Patient'
        }, {
          name: '人事招聘',
          path: 'PersonalMatters'
        }
      ],
      seen: false,
      screenWidth: null,
      menuShow: true,
      menuClose: false,
      storageLeft: true,
      storageRigth: true,
      scroll: '',
      timer: '',
    }
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 700) {
          console.log('屏幕宽度小于700px')
          this.seen = true
        } else {
          this.seen = false
          console.log('屏幕宽度大于700px')
        }
      },
      immediate: true
    },
  },
  created() {
    this.storageLeft = true
    this.storageRigth = true
  },
  mounted() {
    this.seen = false
    this.menuSeen()
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 300) { //判断滚动后高度超过400px,就显示
        this.$refs.btn.style.display = 'block';
      } else {
        this.$refs.btn.style.display = 'none'
      }
    },

    backTop() {
      this.timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer)
        }
      }, 30)
    },

    handleLeftCodeClose() {
      this.storageLeft = false
    },

    handleRigthCodeClose() {
      this.storageRigth = false
    },

    // 拨打电话
    handleCall(tel) {
      this.$refs.tels.href = `tel://${tel}`
      this.$refs.tels.click()
    },
    // 医生入驻
    handleDoctorJoin() {
      this.$router.push({
        name: 'AboutYzy',
        query: {
          activeIndex: "3"
        }
      })
    },

    menuSeen() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
      let screenHeight = document.body.clientWidth;
      if (screenHeight < 700) {
        this.seen = true
      } else {
        this.seen = false
      }
    },
    //点击导航
    handleSelect(path) {
      console.log("path:", path)
      switch (path) {
        case "Home":
          this.$router.push({
            name: "Home"
          });
          break;
        case "About":
          this.$router.push({
            name: "AboutYzy"
          });
          break;
        case "System":
          this.$router.push({
            name: "YzySystem"
          });
          break;
        case "Experts":
          this.$router.push({
            name: "Experts"
          });
          break;
        case "News":
          this.$router.push({
            name: "NewsCenter"
          });
          break;
        case "Patient":
          this.$router.push({
            name: "PatientServe"
          });
          break;
        case "Doctor":
          this.$router.push({
            name: "DoctorServe"
          });
          break;
        case "PersonalMatters":
          this.$router.push({
            name: "PersonalMatters"
          });
          break;
      }
    },
    //点击logo文字
    handleLogeText() {
      this.$router.push({
        name: "Home"
      });
    },
    handleAddress() {
      this.$router.push({
        name: "AboutYzy",
        query: {
          activeIndex: "4"
        }
      });
    },
    handleBottomLoge() {
      console.log(this.$router.name);
      if (this.$route.push != undefined) {
        this.$router.push({
          name: "Home"
        });
      } else {
        this.backTop()
      }
    },
    //  点击展开关闭菜单按钮
    handleMenu() {
      this.menuClose = true
      this.menuShow = false
    },
    handleMenuClose() {
      this.menuClose = false
      this.menuShow = true
    },
    handleAbout() {
      this.$router.push({
        name: "AboutYzy"
      });
    },
    handleCopyRigth() {
      this.$router.push({
        name: "AboutYzy",
        query: {
          activeIndex: "5"
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0d0d0d;
  position: relative;
  width: 100%;
  letter-spacing: 2px;
  // background-image: url('assets/pic_login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.header-container {
  height: 100px;
  background: rgba(255, 200, 168, 0.5);
  // position: fixed;
  // top: 0;
  width: 100%;
  margin: 0 auto;
  animation: header 1s;
  // z-index: 999;
  -moz-animation: header 1s;
  /* Firefox */
  -webkit-animation: header 1s;
  /* Safari and Chrome */
  -o-animation: header 1s;
  /* Opera */
}

@keyframes header {
  0% {
    top: 50px;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes header {
  0% {
    top: 50px;
  }

  100% {
    top: 0;
  }
}

.header-content {
  width: 70%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  // cursor: pointer;
  height: 85%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.header-middle-title {
  font-size: 40px;
}

.logo-image {
  height: 50px;
  width: 100%;
  background: none;
}

.sub-logo-image {
  height: 80px;
  width: 100%;
  background: none;
  // margin-left: 50px;
}

.logo-title-text {
  font-size: 32px;
  color: #f7f7f7;
  letter-spacing: 2px;
  white-space: nowrap;
}

.logo-title-english {
  color: #e0e0e0;
  text-align: center;
}

.navigate {
  width: 100%;
  background-color: #d46a4a;
  height: 50px;
  line-height: 50px;
}

.navigate a {
  text-decoration: none;
  color: #f7f7f7;
}

.navigate a:hover {
  text-decoration: none;
  // color: #0d0d0d;
}

.navigate-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  // margin-left: 20px;
  margin: 0 auto;
  width: 70%;
  // background-color: rgba(212, 106, 74, 0.05);
}

.navigate-text {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.navigate-text:hover {
  background-color: #9d9d9d;
}


.menu-image {
  width: 30px;
  cursor: pointer;
  margin-left: 20px;
}

.foot-container {
  // margin-bottom: 10px;
  background-color: rgba(255, 200, 168, 0.7);
  color: #0d0d0d;
  letter-spacing: 1px;
  margin-top: 10px;
  font-weight: bold;
}

.foot-header {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 0;

  a {
    color: #0d0d0d;
    margin-left: 40px;
    text-decoration: none;
  }

  a:hover {
    color: #d46a4a;
  }
}

.foot-body {
  width: 70%;
  margin: 0 auto;
  padding: 10px 0;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.foot-body-number-info {
  padding: 0 20px;
}

.number-tel:hover {
  cursor: pointer;
}

.foot-logo-container {
  height: 80px;
  // cursor: pointer;
}

.foot-logo-image {
  height: 60px;
  width: 300px;
}

.foot-applet-container {
  display: flex;
}

.foot-applet-image {
  width: 120px;
  height: 120px;
  border-radius: 4px;
}

.foot-applet-doctor {
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.docotor-join-text:hover {
  cursor: pointer;
}

.foot-applet-patient {
  width: 100%;
  text-align: center;
  margin-left: 20px;
  margin-top: 32px;
}

.foot-footer {
  background-color: rgba(255, 200, 168, 0.9);
  padding: 10px 0;
  line-height: 25px;
}

.foot-footer-content {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background: rgba(212,106,74,0.5);
}

.foot-footer-content div:nth-child(1) {
  display: flex;
  align-items: center;
}

.foot-footer-content div:nth-child(2) {
  display: flex;
  align-items: center;

}

.foot-footer-content div:nth-child(2) a {
  text-decoration: none;
  color: #0d0d0d;
  margin-left: 15px;
}

.foot-footer-content div:nth-child(1) a {
  text-decoration: none;
  color: #0d0d0d;
}


//屏幕适应
@media screen and(max-width: 1670px) {
  .foot-applet-doctor {
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }

  .foot-applet-patient {
    width: 100%;
    text-align: center;
    margin-left: 20px;
    margin-top: 0px;
  }
}

@media screen and(max-width: 1450px) {
  .foot-body-number-info {
    padding: 0;
  }

  .foot-footer-content {
    flex-wrap: wrap;
  }

  .foot-footer-content div:nth-child(2) {
    flex-wrap: wrap;
  }

  .foot-footer-content div:nth-child(2) a {
    margin-left: 0;
  }

  .navigate-text {
    width: 130px;
  }
}

@media screen and(max-width: 1350px) {
  .navigate-text {
    width: 100px;
  }

  .header-middle-title {
    font-size: 30px;
  }
}

@media screen and(max-width: 1100px) {
  .navigate-text {
    width: 80px;
  }
}

@media screen and(max-width: 1000px) {
  .header-container {
    width: 70%;
    height: 80px;
  }

  .logo-image {
    height: 50px;
    width: 90%;
    background: none;
  }

  .sub-logo-image {
    height: 50px;
    width: 100%;
    background: none;
    margin-left: 50px;
  }

  .header-content {
    width: 98%;
  }

  .foot-header {
    width: 80%;
  }

  .foot-body {
    width: 80%;
  }
}

@media screen and(max-width: 850px) {
  .header-content {
    width: 98%;
  }

  .foot-header {
    width: 90%;
  }

  .foot-body {
    width: 90%;
  }
}

@media screen and(max-width: 750px) {
  .header-content {
    width: 98%;
  }

  .foot-header {
    width: 100%;
  }

  .foot-body {
    width: 100%;
  }
}

@media screen and(max-width: 680px) {
  .navigate {
    height: 100%;
    line-height: 30px;
  }

  .navigate-container {
    width: 95%;
    flex-wrap: wrap;
    justify-content: normal;
  }

  .foot-header > a {
    color: #0d0d0d;
    margin-left: 5px;
    text-decoration: none;
  }
}

@media screen and(max-width: 546px) {
  .foot-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    flex-wrap: wrap;
    line-height: 25px;
  }

  .foot-logo-image {
    height: 60px;
    width: 260px;
  }
}

@media screen and (max-device-width: 430px) {
  .header-container {
    width: 100%;
  }

  .header-content {
    width: 98%;
    margin: 0 auto;
  }

  .logo-container {
    height: 65%;
    justify-content: center;
  }

  .logo-image {
    height: 40px;
    width: 100%;
  }

  .logo-title-text {
    font-size: 28px;
    color: #f7f7f7;
    letter-spacing: 2px;
  }

  .logo-title-english {
    color: #e0e0e0;
    text-align: center;
  }

  .navigate {
    height: 100%;
    line-height: 30px;
  }

  .navigate-container {
    width: 95%;
    flex-wrap: wrap;
    justify-content: normal;
  }

  .foot-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    //flex-direction: column;
    //align-items: flex-start;
    flex-wrap: wrap;
    line-height: 25px;
    width: 90%;
  }

  .foot-header > a {
    color: #0d0d0d;
    margin-left: 15px;
    text-decoration: none;
  }

  .foot-body {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 90%;
  }

  .foot-body-number-info {
    padding: 0;
  }

  .foot-logo-container {
    margin: 0 auto;
    width: 80%;
    height: 40px;
  }

  .foot-logo-image {
    width: 150px;
  }

  .foot-footer {
    padding: 10px 0;
  }

  .foot-footer-content {
    width: 90%;
  }

  .foot-applet-doctor {
    width: 50%;
    text-align: center;
    margin-top: 0;
  }

  .foot-applet-patient {
    width: 50%;
    text-align: center;
    margin-left: 00px;
    // margin-top: 32px;
  }

  .foot-applet-container {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
  }

  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer
  }
}
</style>

<!--全局样式-->
<style>

</style>
