<template>
	<div class="app-container">
		<div class="map">
			<baidu-map class="bm-view" :scroll-wheel-zoom="true" :center="{lng: 106.62244, lat: 26.62680}" :zoom="18"
				@ready="handler">
				<bm-marker :position="{lng: 106.62244, lat: 26.62680}" @click="infoWindowOpen"
					animation="BMAP_ANIMATION_BOUNCE">
					<bm-info-window :show="show" @close="infoWindowClose"
						@open="infoWindowOpen">贵阳市观山湖区华润社区C4栋</bm-info-window>
				</bm-marker>
			</baidu-map>
		</div>
		<div class="address">
			贵阳市观山湖区华润社区C4栋（可乘贵阳地铁2号线至兴筑路站，B出站口）
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false
			}
		},
		methods: {
			handler({
				BMap,
				map
			}) {
				console.log(BMap, map)
				this.center.lng = 116.404
				this.center.lat = 39.915
				this.zoom = 15
			},
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			}
		}
	}
</script>

<style scoped lang="scss">
	.app-container {
		min-height: 30vh;
	}

	.map {
		margin-top: 32px;
	}

	.bm-view {
		width: 100%;
		height: 300px;
	}

	.address {
		margin-top: 20px;
	}
</style>