import { render, staticRenderFns } from "./personnelMatters.vue?vue&type=template&id=b759e488&scoped=true"
import script from "./personnelMatters.vue?vue&type=script&lang=js"
export * from "./personnelMatters.vue?vue&type=script&lang=js"
import style0 from "./personnelMatters.vue?vue&type=style&index=0&id=b759e488&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b759e488",
  null
  
)

export default component.exports