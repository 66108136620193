<template>
	<div class="app-container">
		<div class="online">
			<el-divider content-position="left">
				<div class="hospital-into-title">
					预约流程
				</div>
			</el-divider>
		</div>
		<div>
			<el-empty description="功能完善中...,敬请期待"></el-empty>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>

<style scoped lang="scss">
	.app-container {
		min-height: 30vh;
	}

	.online {
		margin-top: 32px;
	}

	.hospital-into-title {
		font-size: 20px;
		font-weight: bold;
	}
</style>