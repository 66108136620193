<template>
  <div class="app-container">
    <!-- <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>专家介绍</div>
        <div class="bg-subtitle">EXPERT INTRODUCTION</div>
      </div>
    </div> -->
    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <div class="department-menu">
        <div :class="patchoption==item.deptId?'switch-style':'department-item-list'"
             v-for="(item,index) in departmentItemList" :key="item.deptId"
             @click="handleMenu(index,item.deptId)">
          <el-tooltip effect="dark" :content="item.deptName" placement="bottom-start">
            <div class="department-item-list">{{ item.deptName }}</div>
          </el-tooltip>
        </div>
      </div>
      <div class="search-container">
        <el-input class="export-search" @keyup.enter.native="getDoctorList" v-model="doctorName"
                  placeholder="请输入专家名字"></el-input>
        <el-button icon="el-icon-search" @click="getDoctorList">
          搜索
        </el-button>
      </div>
      <div class="exports-container">
        <div class="export-info" v-for="(item,index) in exportsList" :key="item.doctorId"
             @click="handleExpert(item.doctorId)">
          <a href="">
            <div class="info-header">
              <div>
                <el-image v-if="!item.avatar||item.avatar==null||item.avatar==''"
                          :src="downImg.defaultAvatar" fit="fill" class="image" style="border-radius: 5px;">
                </el-image>
                <el-image v-else :src="item.avatar" fit="fill" class="image"
                          style="border-radius: 5px;">
                </el-image>
                <!-- <div :class="item.doctorSwitch=='1'? 'doctor-online' :'doctor-off'">
                  <span>{{item.doctorSwitch=="1"?"在线":"离线"}}</span>
                </div> -->
              </div>
              <div class="info">
                <div class="name">{{ item.doctorName || "无" }}</div>
                <div class="department">{{ item.deptName || "无" }}</div>
                <div class="title">{{ item.rankName || "无" }}</div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="info-foot">
              <!-- <el-tooltip effect="dark" :content="item.speciality" placement="bottom-start"> -->
              {{ item.introduction || "无" }}
              <!-- </el-tooltip> -->
            </div>
          </a>
        </div>
      </div>
      <div class="pagination">
        <pagination v-show="total > 0" :total="total" :page.sync="pageNum" :limit.sync="pageSize"
                    @pagination="getDoctorList" :background="false" :layout="layout"/>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'

export default {
  data() {
    return {
      bgImage,
      departmentItemList: [],
      exportsList: [],
      // 科室id
      patchoption: 5,
      pageSize: 12,
      total: 0,
      pageNum: 1,
      pathList: [{
        title: "专家介绍"
      }],
      downImg: {
        defaultAvatar: this.$downImage + 'icon_my_mrph.png'
      },
      doctorName: "",
      layout: "total, prev, pager, next, jumper",
      seen: false
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    this.getDepartmentList()
    console.log(this.$route.query);
    if (this.$route.query.deptId) {
      console.log("参数deptid", this.$route.query.deptId);
      this.patchoption = this.$route.query.deptId
    } else {
      this.patchoption = 5
    }
    this.getDoctorList()
  },
  mounted() {
    this.seen = false
    this.menuSeen()
  },
  methods: {
    menuSeen() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
      let screenHeight = document.body.clientWidth;
      if (screenHeight < 700) {
        this.layout = "prev, pager, next"
        this.seen = true
      } else {
        this.seen = false
      }
    },
    getDepartmentList() {
      this.$axios.get(this.$https + `backend/official/deptList`).then(res => {
        let response = res.data
        this.departmentItemList = response.data
      })
    },

    handleMenu(index, deptId) {
      this.patchoption = deptId
      this.getDoctorList()
    },

    getDoctorList() {
      console.log("deptId", this.patchoption);
      this.$axios.get(this.$https +
          `backend/official/doctorList?deptId=${this.patchoption}&pageSize=${this.pageSize}&pageNum=${this.pageNum}&isAsc=desc&orderByColumn=doctorSwitch&doctorName=${this.doctorName}&isAsc=desc&orderByColumn=sort&isAsc=desc&orderByColumn=orderQuantity&isAsc=desc&orderByColumn=doctorId`
      )
          .then(res => {
            this.exportsList = res.data.rows
            this.total = Number(res.data.total)
          })
    },

    handleExpert(doctorId) {
      if (this.isMobile()) {
        this.$router.push({
          name: `ExpertInfo`,
          query: {
            doctorId: doctorId
          }
        })
      } else {
        window.open('/expertInfo?doctorId=' + doctorId, '_blank');
      }
    },

    isMobile() { //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent)
    },
  }
}
</script>

<style scoped lang="scss">
.bg-container {
  height: 400px;
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: -2px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

@keyframes department-menu {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes department-menu {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

.department-menu {
  box-sizing: border-box;
  margin-top: 32px;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
  position: relative;
  top: 0;
  animation: department-menu 1s ease-in-out forwards;
  -moz-animation: department-menu 1s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: department-menu 1s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: department-menu 1s ease-in-out forwards;
  /* Opera */
}

.department-item-list {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switch-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #d46a4a;
}

.switch-style:hover {
  cursor: pointer;
  color: #d46a4a;
}

.department-item-list:hover {
  cursor: pointer;
  color: #d46a4a;
}

.search-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@keyframes export-search {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes export-search {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

.export-search {
  animation: export-search 1.5s ease-in-out forwards;
  -moz-animation: export-search 1.5s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: export-search 1.5s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: export-search 1.5s ease-in-out forwards;
  /* Opera */
}

@keyframes exports-container {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes exports-container {
  0% {
    top: 50vh;
  }

  100% {
    top: 0;
  }
}

.exports-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  position: relative;
  top: 0;
  animation: exports-container 2s ease-in-out forwards;
  -moz-animation: exports-container 2s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: exports-container 2s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: exports-container 2s ease-in-out forwards;
  /* Opera */
}

.export-info {
  background-color: #eeeeee;
  padding: 10px;

  a {
    text-decoration: none;
    color: #0d0d0d;
  }

  a:hover {
    background-color: #d46a4a;
    color: #fff;
    box-shadow: 0 0 3px 5px rgba(212, 106, 74, 0.7);
    cursor: pointer;
  }
}

.export-info:hover {
  background-color: #d46a4a;
  color: #fff;
  box-shadow: 0 0 3px 5px rgba(212, 106, 74, 0.7);
  cursor: pointer;
}

.info-header {
  display: flex;

  .image {
    width: 140px;
    height: 140px;
    background-color: #fff;
    border-radius: 4px;
  }

  .doctor-online {
    text-align: center;
    margin-top: 10px;

    span {
      border: 1px solid #d46a4a;
      border-radius: 2px;
      color: #d46a4a;
      font-weight: bold;
      padding: 2px;
      border-radius: 4px;
    }
  }

  .doctor-off {
    text-align: center;
    margin-top: 10px;

    span {
      border: 1px solid #9d9d9d;
      border-radius: 2px;
      color: #9d9d9d;
      font-weight: bold;
      padding: 2px;
      border-radius: 4px;
    }
  }
}

.info {
  margin-left: 10px;
  line-height: 45px;

  .name {
    font-weight: bold;
    font-size: 18px
  }
}

.info-foot {
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.pagination {
  margin-top: 32px;
  text-align: center;
  position: relative;
  top: 0;
  animation: exports-container 2.5s ease-in-out forwards;
  -moz-animation: exports-container 2.5s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: exports-container 2.5s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: exports-container 2.5s ease-in-out forwards;
  /* Opera */
}

@media screen and(max-width: 1530px) {
  .home-container {
    width: 80%;
  }
}

@media screen and(max-width: 1340px) {
  .home-container {
    width: 90%;
  }
}

@media screen and(max-width: 1200px) {
  .home-container {
    width: 95%;
  }
}

@media screen and(max-width: 1130px) {
  .home-container {
    width: 100%;
  }
}

@media screen and(max-width: 1075px) {
  .home-container {
    width: 100%;
  }

  .info-header {
    display: flex;

    .image {
      width: 100px;
      height: 100px;
      background-color: #fff;
    }
  }

  .info {
    margin-left: 10px;
    line-height: 40px;

    .name {
      font-weight: bold;
      font-size: 16px
    }
  }
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 90%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }

  .department-menu {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .exports-container {
    grid-template-columns: 1fr;
  }
}
</style>
