<template>
  <div>
    <div class="department-container">
      <div class="department-header">
        <div class="department-title">
          <div class="department-title-div">

          </div>
          <div>
            科室导航
          </div>
        </div>
        <div class="department-button">
          <a href="">
            <el-button plain @click="handleMoreDepartment">
              查看更多
            </el-button>
          </a>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="department-body">
        <div class="department-item">
          <div class="department-item-list" v-for="(item1,index1) in departmentList" :key="item1.deptId">
            <el-tooltip effect="dark" :content="item1.deptName" placement="bottom-start">
							<span @click="handleDepartment(index1,item1.deptId)">
								{{ item1.deptName }}
							</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      departmentList: [],
    }
  },
  created() {
    this.getDepartmentList()
  },
  methods: {
    getDepartmentList() {
      this.$axios.get(this.$https + `backend/official/deptList`).then(res => {
        this.departmentList = res.data.data
      })
    },
    handleDepartment(index, deptId) {
      this.$router.push({
        name: "Experts",
        query: {
          deptId: Number(deptId)
        }
      });
    },

    handleMoreDepartment() {
      this.$router.push({
        name: 'Experts'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.department-container {
  margin-top: 40px;
  width: 100%;
}

.department-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.department-title {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
}

.department-title-div {
  background-color: #d46a4a;
  width: 5px;
  height: 50px;
  margin-right: 10px;
}

.department-item {
  margin-top: 10px;
  background-color: #f7f7f7;
}

.department-item-name {
  padding: 24px 24px 0 24px;
  color: #d46a4a;
  font-size: 18px;
  font-weight: bold;
}

.department-item-list {
  display: inline-block;
  padding: 24px;
  width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: #0d0d0d;
  }

  span:hover {
    color: #d46a4a;
    cursor: pointer;
  }
}

@media screen and (max-device-width: 430px) {
  .department-item-list {
    width: 120px;
  }
}
</style>
