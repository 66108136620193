<template>
	<div class="app-container">
		<div>
			<div class="menu-item" v-for="(item,index) in menuList" :key="index" @click="handleMenu(item.path)">
				<a href="">
					{{item.name}}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: {
			menuList: {
				type: Array,
				default: []
			}
		},
		methods: {
			handleMenu(path) {
				switch (path) {
					case "Home":
						this.$router.push({
							name: "Home"
						});
						break;
					case "About":
						this.$router.push({
							name: "AboutYzy"
						});
						break;
					case "System":
						this.$router.push({
							name: "YzySystem"
						});
						break;
					case "Experts":
						this.$router.push({
							name: "Experts"
						});
						break;
					case "News":
						this.$router.push({
							name: "NewsCenter"
						});
						break;
					case "Patient":
						this.$router.push({
							name: "PatientServe"
						});
						break;
					case "Doctor":
						this.$router.push({
							name: "DoctorServe"
						});
						break;
					case "PersonalMatters":
						this.$router.push({
							name: "PersonalMatters"
						});
						break;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.app-container {
		position: fixed;
		right: 10px;
		top: 8vh;
		color: #d46a4a;
		font-weight: bold;
	}

	.menu-item {
		background-color: #f1f1f1;
		border-bottom: 1px solid;
		padding: 5px;

		a {
			text-decoration: none;
			color: #d46a4a;
		}
	}
</style>