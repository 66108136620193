<template>
  <div class="app-container">
    <div class="body">
      <el-card>
        <div class="image-container" v-for="(item,index) in imageList" :key="index">
          <!-- <el-popover placement="top-start" width="300" trigger="click"> -->
          <el-image class="el-image" :src="item.name" fit="fill">
          </el-image>
          <!-- <el-image slot="reference" class="el-image" :src="item.name" fit="fill">
            </el-image> -->
          <!-- </el-popover> -->
        </div>
      </el-card>
    </div>
    <!-- <div class="pagination">
      <el-pagination :page-size="pagesSize" background layout="prev, pager, next, jumper" :total="pagesTotal">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagesTotal: 0,
      pagesSize: 10,
      imageList: [{
        name: require('@/assets/honor/图片7.png')
      }, {
        name: require('@/assets/honor/图片6.png')
      }, {
        name: require('@/assets/honor/图片5.png')
      }, {
        name: require('@/assets/honor/图片4.png')
      }, {
        name: require('@/assets/honor/图片3.png')
      }, {
        name: require('@/assets/honor/图片1.png')
      }, {
        name: require('@/assets/honor/图片2.png')
      }, {
        name: require('@/assets/honor/图片8.png')
      }, {
        name: require('@/assets/honor/图片9.png')
      }, {
        name: require('@/assets/honor/图片10.png')
      }, {
        name: require('@/assets/honor/图片11.png')
      }, {
        name: require('@/assets/honor/图片12.png')
      }, {
        name: require('@/assets/honor/图片13.png')
      }, {
        name: require('@/assets/honor/图片14.png')
      }]
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.app-container {
  min-height: 30vh;
}

.body {
  margin-top: 32px;
}

.pagination {
  margin-top: 32px;
  text-align: center;
}


.image-container {
  display: inline-block;

  .el-image {
    width: 300px;
    height: 400px;
    margin: 10px;
    cursor: pointer;
  }

  .el-image:hover {
    transform: scale(1.1);
  }

  .el-image-preview {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-device-width: 430px) {
  .image-container {
    display: block;
    margin-bottom: 20px;

    .el-image {
      width: 100%;
      margin: 0;
    }
  }

}
</style>
