<template>
	<div class="app-container">
		<el-image :src="location" class="el-image"></el-image>
		<div class="name">
			您当前的位置：
		</div>
		<el-breadcrumb>
			<el-breadcrumb-item :to="{ path: '/' }">
				<a href="">
					网站首页
				</a>
			</el-breadcrumb-item>
			<el-breadcrumb-item v-for="(item,index) in path" :key="index" :to="{ path: item.path}">
				<a href="">
					{{item.title}}
				</a>
			</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
	import location from '@/assets/location.png'
	export default {
		name: "",
		data() {
			return {
				location
			}
		},
		props: {
			path: {
				type: Array,
				defualt: []
			}
		}
	}
</script>

<style scoped lang="scss">
	.app-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 20px 5px;
		font-size: 16px;
		color: #606266;
		background-color: rgba(241, 241, 241, .5);
		z-index: 2;

		.el-image {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}

		.el-breadcrumb {
			font-size: 16px;
		}

		a:hover {
			cursor: pointer;
			color: #d46a4a;
		}
	}

	@media screen and (max-device-width: 415px) {
		.app-container {

			.el-image {
				display: none;
			}

			.name {
				display: none;
			}
		}
	}
</style>