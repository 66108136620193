<template>
  <div class="app-container">
    <!-- <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>医院概况</div>
        <div class="bg-subtitle">HOSPITAL OVERVIEW</div>
      </div>
    </div> -->
    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <div class="nav-menu">
        <el-menu :default-active="activeIndex" background-color="#fff" active-text-color="#d46a4a"
                 text-color="#0d0d0d" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="(index + 1)+''" v-for="(item,index) in menuList" :key="index">
            {{ item.name }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="body">
        <hospital-intro v-if="activeIndex=='1'"></hospital-intro>
        <Honor v-if="activeIndex=='2'"></Honor>
        <doctor-join v-if="activeIndex=='3'"></doctor-join>
        <pharmacy-map v-if="activeIndex=='4'"></pharmacy-map>
        <copy-right v-if="activeIndex=='5'"></copy-right>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'
import HospitalIntro from './hospitalIntro/index'
import Honor from './honor/index'
import PharmacyMap from './pharmacyMap/index'
import OnlineReservation from './onlineReservation/index'
import DoctorJoin from './doctorJoin/index'
import CopyRight from './copyRigth/index.vue'

export default {
  components: {
    HospitalIntro,
    Honor,
    PharmacyMap,
    OnlineReservation,
    DoctorJoin,
    CopyRight
  },
  data() {
    return {
      bgImage,
      activeIndex: "1",
      isShow: false,
      menuList: [{
        name: "医院简介"
      }, {
        name: "医院荣誉"
      },
        // {
        // 	name: "在线预约"
        // }, {
        // 	name: "咨询问诊"
        // },
        {
          name: "医生入驻"
        }, {
          name: "医院地图"
        }, {
          name: "版权声明"
        }
      ],
      pathList: [{
        title: "医院概况"
      }]
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    let url = location.href;
    this.getUrlParams(url)
    let params = this.getUrlParams(url)
    if (params.activeIndex) {
      this.activeIndex = params.activeIndex
    } else {
      this.activeIndex = "1"
    }
  },
  mounted() {

  },
  methods: {
    getUrlParams(url) {
      let urlStr = url.split('?')[1]
      let result = Qs.parse(urlStr)
      // // 拼接额外参数
      // let otherParams = {
      // 	num: 50,
      // 	size: 6.1
      // }
      // let str = Qs.stringify(otherParams)
      // let newUrl = url + str
      // return {
      // 	result,
      // 	newUrl
      // }
      return result
    },

    handleSelect(key, keyPath) {
      this.activeIndex = keyPath[0]
    },

  }
}
</script>

<style scoped lang="scss">
.bg-container {
  position: relative;
  height: 400px;
  width: 70%;
  margin: 0 auto;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;

}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: 0px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

.nav-menu {
  a {
    text-decoration: none;
  }
}

@media screen and(max-width: 1200px) {
  .home-container {
    width: 80%;
  }
}

@media screen and(max-width: 1000px) {
  .home-container {
    width: 90%;
  }
}

@media screen and(max-width: 900px) {
  .home-container {
    width: 95%;
  }
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 90%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
