import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入axios
import axios from 'axios';
Vue.prototype.$axios = axios

// 正式环境：https://yzy.gzyzyy.cn/prod-api/
// 测试环境：http://dev.gzyzyy.cn:8080/
let http = 'https://yzy.gzyzyy.cn/prod-api/'
Vue.prototype.$https = http

Vue.prototype.$downImage = 'https://yzy-saas.oss-cn-chengdu.aliyuncs.com/patientstatic/'


// 全局注册组件
import SelfBbreadcrumb from '@/components/SelfBbreadcrumb'
Vue.component('SelfBbreadcrumb', SelfBbreadcrumb)

// 图片预览组件
import ImagePreview from '@/components/ImagePreview'
Vue.component('ImagePreview', ImagePreview)

// 分页组件
import Pagination from '@/components/Pagination'
Vue.component('Pagination', Pagination)

// 地图组件
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
	ak: 'PlhFWpA02aoURjAOpnWcRGqw7AI8EEyO'
})

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')