<template>
  <div>
    <div class="card-container">
      <div class="card-content">
        <div class="scan-code" v-if="!seen">
          <!-- <el-image :src="appletImage" class="scan-code-image"></el-image> -->
          就医指南 →
        </div>
        <div class="scan-container">
          <div class="scan-item" v-for="(item,index) in cardList" :key="index">
            <el-image :src="item.img" class="scan-image" @click="handleCard(item)"></el-image>
            <div @click="handleCard(item)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appletImage from '@/assets/applet.png'

export default {
  data() {
    return {
      appletImage,
      cardList: [{
        name: '咨询问诊',
        href: 'PatientServe',
        pamaras: "1",
        img: require('@/assets/consulation.png')
      }, {
        name: '科室导航',
        href: 'Experts',
        pamaras: "5",
        img: require('@/assets/department_router.png')
      }, {
        name: '医保政策',
        href: 'PatientServe',
        pamaras: "2",
        img: require('@/assets/med-image.png')
      }, {
        name: '来院路线',
        href: 'AboutYzy',
        pamaras: "4",
        img: require('@/assets/location.png')
      }],
      seen: false
    }
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        if (val < 700) {
          console.log('屏幕宽度小于700px')
          this.seen = true
        } else {
          this.seen = false
          console.log('屏幕宽度大于700px')
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.seen = false
    this.menuSeen()
  },
  methods: {
    menuSeen() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
      let screenHeight = document.body.clientWidth;
      if (screenHeight < 700) {
        this.seen = true
      } else {
        this.seen = false
      }
    },
    handleCard(item) {
      this.$router.push({
        name: item.href,
        query: {
          activeIndex: item.pamaras
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card-container {
  // background-color: #d46a4a;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #d46a4a;
  margin-top: 32px;
  width: 100%;
  // padding: 10px 0;
  color: #0d0d0d;
  position: relative;
  left: 100%;
  animation: cardContainer 1s ease-in-out forwards;
  -moz-animation: cardContainer 1s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: cardContainer 1s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: cardContainer 1s ease-in-out forwards;
  /* Opera */
}

@keyframes cardContainer {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

@-webkit-keyframes cardContainer {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

.card-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  // justify-content: space-between;
  align-items: center;
  height: 100px;
}

.scan-code {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-image: url('../../../assets/pic_login_bg.png');
  // padding: 50px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100px;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.scan-code-image {
  width: 99px;
  height: 99px;
}

.scan-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.scan-item {
  text-decoration: none;
  color: #0d0d0d;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-item:hover {
  cursor: pointer;
}

.scan-image {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.scan-code div:nth-child(2) {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
}

@media screen and(max-width: 1450px) {
  .card-content {
    flex-wrap: wrap;
  }

  .scan-item span {
    font-size: 28px;
  }

  .scan-code-image {
    width: 88px;
    height: 88px;
  }

  .scan-code div:nth-child(2) {
    font-size: 14px;
  }
}

@media screen and(max-width: 1000px) {
  .scan-item span {
    font-size: 24px;
  }
}

@media screen and(max-width: 850px) {
  .scan-item a {
    font-size: 20px;
  }
}

@media screen and(max-width: 680px) {
  .scan-item a {
    font-size: 16px;
  }
}

@media screen and (max-device-width: 430px) {
  .card-container {
    margin-top: 20px;
    width: 100%;
  }

  .card-content {
    width: 95%;
    display: unset;
  }

  .scan-image {
    width: 30px;
    height: 30px;
  }

  .scan-item {
    text-decoration: none;
    color: #0d0d0d;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scan-code-image {
    width: 76px;
    height: 76px;
  }

  .scan-code div:nth-child(2) {
    font-size: 14px;
  }
}
</style>
