<template>
  <div class="app-container">
    <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>医院概况</div>
        <div class="bg-subtitle">HOSPITAL OVERVIEW</div>
      </div>
    </div>

    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'

export default {
  name: "",
  data() {
    return {
      bgImage,
      pathList: [{
        title: "医院概况",
        path: "/aboutYzy"
      }, {
        title: "领导班子"
      }]
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.bg-container {
  position: relative;
  height: 450px;
}

.bg-container-phone {
  position: relative;
  height: 250px;
}

.bg-image {
  width: 100%;
  height: 100%;

}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: 0px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}
</style>
