<template>
  <div class="app-container">
    <!-- <div :class="bgContainer" v-if="homePath!=''">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>新闻中心</div>
        <div class="bg-subtitle">NEWS CENTER</div>
      </div>
    </div> -->
    <div class="home-container" v-if="homePath!=''">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <div id="医院动态">
        <el-divider content-position="left">
          <div class="hospital-into-title">
            医院动态
          </div>
        </el-divider>
      </div>
      <div v-if="newsCenterList.length==0" style="text-align: center;margin-top: 20px;">
        <el-empty :image-size="50"></el-empty>
      </div>
      <div class="news-notice-item" v-for="(item,index) in newsCenterList" :key="item.noticeId"
           @click="handleNewsDtl(item.noticeId)">
        <a href="">
          {{ item.noticeTitle }}
        </a>
      </div>
      <div class="pagination">
        <pagination v-show="totalNews > 0" :total="totalNews" :page.sync="pageNumNews"
                    :limit.sync="pageSizeNews" @pagination="getNewList" :background="false" :layout="layout"/>
      </div>
      <div id="院内公告">
        <el-divider content-position="left">
          <div class="hospital-into-title">
            院内公告
          </div>
        </el-divider>
      </div>
      <div v-if="noticeList.length==0" style="text-align: center;margin-top: 20px;">
        <el-empty :image-size="50"></el-empty>
      </div>
      <div v-else class="news-notice-item" v-for="(item,index) in noticeList" :key="item.noticeId"
           @click="handleNewsDtl(item.noticeId)">
        <a href="">
          {{ item.noticeTitle }}
        </a>
      </div>
      <div class="pagination">
        <pagination v-show="total > 0" :total="total" :page.sync="pageNum" :limit.sync="pageSize"
                    @pagination="getNoticeList" :background="false" :layout="layout"/>
      </div>
    </div>
    <div v-else>
      <div>
        <el-divider content-position="left">
          <div class="hospital-into-title">
            医院动态
          </div>
        </el-divider>
      </div>
      <div v-if="newsCenterList.length==0" style="text-align: center;margin-top: 20px;">
        <el-empty :image-size="50"></el-empty>
      </div>
      <div class="news-notice-item" v-for="(item,index) in newsCenterList" :key="item.noticeId"
           @click="handleNewsDtl(item.noticeId)">
        <a href="">
          {{ item.noticeTitle }}
        </a>
      </div>
      <div class="pagination">
        <pagination v-show="totalNews > 0" :total="totalNews" :page.sync="pageNumNews"
                    :limit.sync="pageSizeNews" @pagination="getNewList" :background="false" :layout="layout"/>
      </div>
      <div>
        <el-divider content-position="left">
          <div class="hospital-into-title">
            院内公告
          </div>
        </el-divider>
      </div>
      <div v-if="noticeList.length==0" style="text-align: center;margin-top: 20px;">
        <el-empty :image-size="50"></el-empty>
      </div>
      <div v-else class="news-notice-item" v-for="(item,index) in noticeList" :key="item.noticeId"
           @click="handleNewsDtl(item.noticeId)">
        <a href="">
          {{ item.noticeTitle }}
        </a>
      </div>
      <div class="pagination">
        <pagination v-show="total > 0" :total="total" :page.sync="pageNum" :limit.sync="pageSize"
                    @pagination="getNoticeList" :background="false" :layout="layout"/>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'

export default {
  name: "",
  data() {
    return {
      bgImage,
      pathList: [{
        title: "新闻列表",
        path: "/newsCenter"
      }],
      newsCenterList: [],
      noticeList: [],
      pageSizeNews: 5,
      totalNews: 0,
      pageNumNews: 1,
      pageSize: 5,
      total: 0,
      pageNum: 1,
      homePath: "",
      seen: false,
      layout: "total, prev, pager, next, jumper",
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    console.log(this.$route.query.homePath);
    let path = this.$route.query.homePath
    if (path && path != '' && typeof path != undefined) {
      this.homePath = this.$route.query.homePath
    } else {
      this.homePath = ""
    }
    let type = this.$route.query.articleType
    if (type && type != '' && typeof type != undefined) {
      if (type == "医院动态") {
        this.hashPageNews()
      } else {
        this.hashPageNotice()
      }
    }
    this.getNewList()
    this.getNoticeList()
  },
  mounted() {
    this.seen = false
    this.menuSeen()
  },
  methods: {
    menuSeen() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
      let screenHeight = document.body.clientWidth;
      if (screenHeight < 700) {
        this.layout = "prev, pager, next"
        this.seen = true
      } else {
        this.seen = false
      }
    },
    hashPageNews() {
      window.location.hash = "#医院动态"
    },

    hashPageNotice() {
      window.location.hash = "#院内公告"
    },

    getNewList() {
      this.$axios.get(this.$https +
          `backend/official/notice?noticeType=1&pageSize=${this.pageSizeNews}&pageNum=${this.pageNumNews}`)
          .then(res => {
            console.log(res);
            let response = res.data
            this.newsCenterList = response.rows
            this.totalNews = response.total
          })
    },

    getNoticeList() {
      this.$axios.get(this.$https +
          `backend/official/bulletin?noticeType=2&pageSize=${this.pageSize}&pageNum=${this.pageNum}`).then(
          res => {
            console.log(res);
            let response = res.data
            this.noticeList = response.rows
            this.total = response.total
          })
    },

    handleNewsDtl(id) {
      if (this.isMobile()) {
        this.$router.push({
          path: `/newsDtl`,
          query: {
            noticeId: id
          }
        })
      } else {
        window.open('/newsDtl?noticeId=' + id, '_blank');
      }
    },

    isMobile() { //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent)
    },
  }
}
</script>

<style scoped lang="scss">
.hospital-into-title {
  font-size: 20px;
  font-weight: bold;
}

.news-notice-item {
  padding: 20px 0;
  border-bottom: 2px dotted #dddddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-notice-item > a {
  color: #0d0d0d;
  text-decoration: none;
}

.news-notice-item > a:hover {
  color: #d46a4a;
  text-decoration: none;
}

.bg-container {
  height: 400px;
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.pagination {
  text-align: center;
}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: 4.5px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 90%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
