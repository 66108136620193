<template>
  <div class="app-container">
    <div class="introduction">
      <div class="hospital-into-container">
        <div>
          <el-divider content-position="left">
            <div class="hospital-into-title">
              医院简介
            </div>
          </el-divider>
        </div>
        <div class="hospital-into-content">
          <div>
            贵州云中医院是2020年8月经卫健委批准正式挂牌成立，为二级综合实体医疗机构。
            内设：中医科、内科、外科、妇科、疼痛科、检验科、放射科、中心药房、住院治疗
            服务等。
          </div>
          <div>
            贵州云中医院还是贵州首家互联网中医院，拥有8项独立知识产权，患者可享线上咨
            询、专家线上诊疗、线下特色专科、药物快递到家等服务。致力于为大家提供专业化、
            智能化、现代化的就医平台。现云中医平台注册医生三千余人，患者量十万余。
          </div>
          <!-- <div>
            贵州云中医院【云中医平台】于2016年6月初引入中药配方颗粒自动调配系统并正式投入使用，将中药经过提炼有效成分，变成3-5分钟就可溶于水的小颗粒，患者不用熬药就可以直接冲服。现已形成以庄田畋、袁端红、青成言、饶海、陈世波、韩子翼、陈文精、田景玉、康清丽、田静、潘年松、喻沾俊、安玉禄、姚海江、李道睿、江锋、高铸烨（排名不分先后）等100多位中医专家为代表的高精尖医生团队，为贵州云中医院、贵阳爱丽医院的再次发展壮大打下坚实的专业基础。我院投入巨资，组织网络技术专家团队，经过一年多时间的潜心开发，终于于2017年6月正式上线【云中医平台】，初步实现了网上咨询、网络预约、专家在线视频看病、在线支付、药物快递到家等特色医疗服务，巧妙将现代网络与医疗服务相结合，解决了患者看病难，排队挂号费时费力等就医难题。
          </div>
          <div>
            面对中医药事业发展新的机制和挑战，医院秉承“穷医道精髓，献人术爱心”院训，以病人为中心，不断提高医疗服务质量，以现代中医药特色为主的开放型互联网医院为发展目标，致力于为广大人民群众提供优质、高效、安全的中医药医疗卫生服务，通过“互联网+”技术，发扬中国中医文化。
          </div> -->
        </div>
      </div>
      <div class="hospital-image">
        <el-image :src="bgImage" class="el-image"></el-image>
      </div>
    </div>
    <div class="leader">
      <div>
        <el-divider content-position="left">
          <div class="hospital-into-title">
            核心团队
          </div>
        </el-divider>
      </div>
      <div class="leader-container">
        <div v-for="(item,index) in leaderList" class="leader-item" :key="index" @click="handleLeader(item.id)">
          <el-image v-if="item.image==''" :src="downImg.defaultAvatar" class="el-image-default"></el-image>
          <el-image v-else :src="item.image" class="el-image"></el-image>
          <div>{{ item.name }}</div>
          <div>{{ item.type }}</div>
        </div>
      </div>
    </div>
    <div class="history">
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/yzy_storefront.png'

export default {
  data() {
    return {
      bgImage,
      downImg: {
        defaultAvatar: this.$downImage + 'icon_my_mrph.png'
      },
      leaderList: [{
        image: require('@/assets/leader/xuqihui.jpg'),
        name: "徐齐辉",
        type: "院长"
      }, {
        image: require('@/assets/leader/wangxiuhuai.png'),
        name: "王修怀",
        type: "业务院长"
      }, {
        image: require('@/assets/leader/liuli.jpg'),
        name: "刘莉",
        type: "副院长"
      }, {
        image: require('@/assets/leader/chenmin.jpg'),
        name: "陈敏",
        type: "办公室 主任"
      }, {
        image: require('@/assets/leader/panxia.jpg'),
        name: "潘霞",
        type: "总会计师"
      },
        // {
        // 	image: require('@/assets/leader/zhangsihan.jpg'),
        // 	name: "张似含",
        // 	type: "网络部 主管"
        // },
        {
          image: require('@/assets/leader/luoshiheng.jpg'),
          name: "罗仕恒",
          type: "网络部 技术总监"
        }, {
          image: require('@/assets/leader/dengya.jpg'),
          name: "邓娅",
          type: "运营部 主管"
        }, {
          image: require('@/assets/leader/weixuan.png'),
          name: "魏瑄",
          type: "门诊部 副主任"
        }, {
          image: require('@/assets/leader/longjunwei.jpg'),
          name: "龙俊玮",
          type: "康复科 主任"
        }, {
          image: require('@/assets/leader/shenhai.png'),
          name: "申海",
          type: "药剂科 副主任"
        }, {
          image: require('@/assets/leader/guoli.jpg'),
          name: "郭丽",
          type: "总护士长"
        }
      ]
    }
  },
  methods: {
    handleLeader(id) {
      // this.$router.push({
      // 	path: `/leaderDtl/${id}`
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.app-container {
  min-height: 30vh;
}

.introduction {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.hospital-into-container {
  flex: 0.58;
}

.hospital-image {
  flex: 0.4;

  .el-image {
    height: 100%;
  }
}

.hospital-into-title {
  font-size: 20px;
  font-weight: bold;
}

.hospital-into-content {
  text-align: justify;
  text-indent: 2em;
  letter-spacing: 2px;
  color: #0d0d0d;
  line-height: 24px;
}

.leader {
  margin-top: 50px;

  .el-image {
    width: 150px;
    height: 200px;
    margin-bottom: 10px;
    border-radius: 4px 4px 0 0;
  }

  .el-image-default {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
}

.leader-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-around;
  background-color: #f7f7f7;
  padding: 32px 0;
}

.leader-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  // padding: 20px;
  line-height: 25px;
  // cursor: pointer;
  margin: 20px;
  border-radius: 4px;

  // a {
  // 	text-decoration: none;
  // 	color: #0d0d0d;
  // }

  // a:hover {
  // 	text-decoration: none;
  // 	color: #0d0d0d;
  // 	color: #d46a4a;
  // }
  height: 260px;
}

@media screen and (max-device-width: 430px) {
  .introduction {
    display: flex;
    flex-direction: column;
  }

  .leader-container {
    flex-wrap: wrap;
    padding: 0;
  }

  .leader-item {
    margin-top: 10px;
    padding: 10px;
    font-size: 12px;
    width: 140px;
  }

  .leader {
    margin-top: 30px;

    .el-image {
      width: 100px;
      height: 150px;
      margin-bottom: 10px;
    }
  }
}
</style>
