<template>
  <div class="app-container">
    <!--    网站首页-->
    <el-carousel :height="computeHeight" class="carousel-container">
      <el-carousel-item v-for="(item, index) in sliders" :key="index">
        <el-image :src="item.bananerImage" class="slider_img" fit="fill"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="home-container">
      <!--      首页快捷卡片-->
      <QuickCard></QuickCard>
      <!-- 新闻中心 院内公告 -->
      <NewsAndHospital></NewsAndHospital>
      <!-- 科室 -->
      <Department></Department>
    </div>
  </div>
</template>

<script>
import QuickCard from './quickCard/quickCard.vue'
import NewsAndHospital from './newsAndHospital/newsAndHospital.vue'
import Department from './department/department.vue'

export default {
  components: {
    QuickCard,
    NewsAndHospital,
    Department
  },
  data() {
    return {
      sliders: []
    }
  },
  computed: {
    // 计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return '400px';
      } else {
        return '180px';
      }
    },
  },
  mounted() {
    this.getBannerList()
  },
  methods: {
    getBannerList() {
      this.$axios.get(this.$https + `backend/official/banner?bananerType=4&isAsc=desc&orderByColumn=updateTime`).then(res => {
        let response = res.data
        this.sliders = response.data
      })
    },
  }
}
</script>

<style scoped lang="scss">
.app-container {
  z-index: 1;
}

.carousel-container {
  box-shadow: 0px 5px 5px #9d9d9d;
  width: 70%;
  margin: 0 auto;
}

.slider_img {
  height: 400px;
  width: 100%;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

@media screen and(max-width: 1450px) {
}

@media screen and(max-width: 1000px) {
  .home-container {
    width: 80%;
  }
}

@media screen and(max-width: 850px) {
}

@media screen and(max-width: 680px) {
}

@media screen and (max-device-width: 430px) {
  .slider_img {
    height: 180px;
    width: 100%;
  }

  .home-container {
    width: 90%;
  }

  .carousel-container {
    width: 90%;
  }
}
</style>
