<template>
	<div class="app-container">
		<div :class="bgContainer">
			<el-image :src="bgImage" class="bg-image"></el-image>
			<div class="bg-title">
				<div>云中医系统</div>
				<div class="bg-subtitle">HOSPITAL SYSTEM</div>
			</div>
		</div>
		<div class="home-container">
			<div class="nav-menu">
				<el-menu :default-active="activeIndex" active-text-color="#d46a4a" text-color="#0d0d0d"
					class="el-menu-demo" mode="horizontal" @select="handleSelect">
					<el-menu-item index="1">医院简介</el-menu-item>
					<el-menu-item index="2">医院荣誉</el-menu-item>
				</el-menu>
			</div>
			<div>

			</div>
		</div>
	</div>
</template>

<script>
	import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'
	export default {
		data() {
			return {
				bgImage,
				activeIndex: '1'
			}
		},
		computed: {
			// 计算高度
			bgContainer() {
				let screenHeight = document.body.clientWidth;
				if (screenHeight > 415) {
					return 'bg-container';
				} else {
					return 'bg-container-phone';
				}
			},
		},
		mounted() {

		},
		methods: {}
	}
</script>

<style scoped>
	.bg-container {
		position: relative;
		height: 450px;
	}

	.bg-container-phone {
		position: relative;
		height: 250px;
	}

	.bg-image {
		width: 100%;
		height: 100%;
	}

	.bg-title {
		position: absolute;
		bottom: 15vh;
		right: 20vh;
		font-size: 45px;
		letter-spacing: 5px;
		font-weight: bold;
		color: #d46a4a;
		text-align: center;
	}

	.bg-subtitle {
		background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		letter-spacing: 4.5px;
	}

	.bg-subtitle:first-letter {
		font-size: 32px;
		font-weight: bold;
	}

	.home-container {
		width: 70%;
		margin: 0 auto;
	}

	.nav-menu {

		a {
			text-decoration: none;
		}
	}

	@media screen and(max-width: 1200px) {
		.home-container {
			width: 80%;
		}
	}

	@media screen and(max-width: 1000px) {
		.home-container {
			width: 90%;
		}
	}

	@media screen and(max-width: 900px) {
		.home-container {
			width: 95%;
		}
	}

	@media screen and (max-device-width: 415px) {
		.home-container {
			width: 90%;
		}

		.bg-title {
			position: absolute;
			bottom: 6vh;
			right: 6vh;
			font-size: 30px;
			letter-spacing: 2px;
			font-weight: bold;
			color: #d46a4a;
			text-align: center;
		}

		.bg-subtitle {
			background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 10px;
			letter-spacing: 0px;
		}

		.bg-subtitle:first-letter {
			font-size: 20px;
			font-weight: bold;
		}
	}
</style>
