<template>
  <div class="app-container">
    <!-- <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>患者服务</div>
        <div class="bg-subtitle">PATIENT SERVE</div>
      </div>
    </div> -->
    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <div class="nav-menu">
        <el-menu :default-active="activeIndex" background-color="#fff" active-text-color="#d46a4a"
                 text-color="#0d0d0d" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="(index + 1)+''" v-for="(item,index) in menuList" :key="index">
            {{ item.name }}
          </el-menu-item>
        </el-menu>
      </div>
      <div class="body">
        <consulation v-if="activeIndex=='1'"></consulation>
        <med-info v-if="activeIndex=='2'"></med-info>
        <!-- <Honor v-if="activeIndex=='2'"></Honor>
        <doctor-join v-if="activeIndex=='3'"></doctor-join>
        <pharmacy-map v-if="activeIndex=='4'"></pharmacy-map> -->
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'
import Consulation from './consulation/index'
import MedInfo from './medInfo/index.vue'

export default {
  components: {
    Consulation,
    MedInfo
  },
  data() {
    return {
      bgImage,
      pathList: [{
        title: "患者服务"
      }],
      activeIndex: "1",
      menuList: [{
        name: "咨询问诊"
      },
        {
          name: "医保政策"
        },
        // {
        // 	name: "在线预约"
        // }, {
        // 	name: "咨询问诊"
        // },
        // {
        // 	name: "医生入驻"
        // }, {
        // 	name: "医院地图"
        // }
      ],
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    console.log(this.$route.query.activeIndex);
    let activeIndex = this.$route.query.activeIndex
    if (activeIndex) {
      this.activeIndex = activeIndex
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = keyPath[0]
    },
  }
}
</script>

<style scoped>
.bg-container {
  height: 400px;
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: 1.5px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 90%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
