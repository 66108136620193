<template>
  <div class="app-container">
    <!-- <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>人事招聘</div>
        <div class="bg-subtitle">PERSONNEL MATTERS</div>
      </div>
    </div> -->
    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <el-empty v-if="!data" :image-size="50">
      </el-empty>
      <div v-else style="margin-top: 20px;">
        <div v-html="data.noticeContent" class="content-common-content"></div>
        <div style="color: #9d9d9d;padding: 20px 0;">
          时间：{{ data.updateTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'

export default {
  data() {
    return {
      bgImage,
      pathList: [{
        title: "人事招聘"
      }],
      data: {
        noticeTitle: "",
        noticeContent: "",
        updateTime: ""
      }
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$axios.get(this.$https + `user/protocol?noticeType=16&noticeAppType=5`).then(res => {
        if (res.data.data) {
          this.data = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.bg-container {
  height: 400px;
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: -1.5px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 90%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
