<template>
  <div>
    <div class="carousel-container">
      <div class="carousel-div">
        <el-carousel :height="computeHeightMin" :interval="6000" arrow="nerver" indicator-position="none">
          <el-carousel-item v-for="(item, index) in topBannerList" :key="index">
            <img :src="item.bananerImage" class="slider_img_min">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="news-center news-notice-bg">
        <div class="news-notice-header">
          <div class="news-notice-title">
            <div>
              医院动态
            </div>
            <div class="news-notice-line"></div>
          </div>
          <div class="news-notice-a" @click="handleMore('医院动态')">
            <a href="">
              查看更多>>
            </a>
          </div>
        </div>
        <div class="news-notice-content">
          <div v-if="newsCenterList.length==0">
            <el-empty :image-size="50"></el-empty>
          </div>
          <div v-else class="news-notice-item" v-for="(item,index) in newsCenterList" :key="item.noticeId"
               @click="handleNewsDtl(item.noticeId)">
            <a href="">
              {{ item.noticeTitle }}
            </a>
          </div>
        </div>
      </div>
      <div class="hospital-notice news-notice-bg">
        <div class="news-notice-header">
          <div class="news-notice-title">
            <div>
              院内公告
            </div>
            <div class="news-notice-line"></div>
          </div>
          <div class="news-notice-a" @click="handleMore('院内公告')">
            <a href="">
              查看更多>>
            </a>
          </div>
        </div>
        <div class="news-notice-content">
          <div v-if="noticeList.length==0">
            <el-empty :image-size="50"></el-empty>
          </div>
          <div v-else class="news-notice-item" v-for="(item,index) in noticeList" :key="item.noticeId"
               @click="handleNewsDtl(item.noticeId)">
            <a href="">
              {{ item.noticeTitle }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topBannerList: [],
      newsCenterList: [],
      noticeList: []
    }
  },
  computed: {
    // 计算高度
    computeHeightMin() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return '350px';
      } else {
        return '200px';
      }
    },
  },
  created() {
    this.getBannerList()
    this.getNewList()
    this.getNoticeList()
  },
  methods: {
    getBannerList() {
      this.$axios.get(this.$https + `backend/official/banner?bananerType=5&isAsc=desc&orderByColumn=updateTime`)
          .then(res => {
            let response = res.data
            this.topBannerList = response.data
          })
    },

    getNewList() {
      this.$axios.get(this.$https + `backend/official/notice?noticeType=1&pageSize=5`).then(res => {
        let response = res.data
        this.newsCenterList = response.rows
      })
    },

    getNoticeList() {
      this.$axios.get(this.$https + `backend/official/bulletin?noticeType=2&pageSize=5`).then(res => {
        let response = res.data
        this.noticeList = response.rows
      })
    },

    handleMore(type) {
      this.$router.push({
        path: `/newsList`,
        query: {
          homePath: "homePath",
          articleType: type
        }
      })
    },

    handleNewsDtl(id) {
      if (this.isMobile()) {
        console.log("这是手机");
        this.$router.push({
          path: `/newsDtl`,
          query: {
            noticeId: id
          }
        })
      } else {
        console.log('这是pc')
        window.open('/newsDtl?noticeId=' + id, '_blank');
      }
    },

    isMobile() { //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent)
    },
  }
}
</script>

<style scoped lang="scss">
.slider_img_min {
  height: 350px;
  width: 100%;
}

.carousel-container {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
}

.carousel-div {
  width: 40%;
  border: 1px solid #f1f1f1;
}

.news-center {
  position: relative;
  left: 100%;
  width: 28%;
  animation: newsCenter 1s ease-in-out forwards;
  -moz-animation: newsCenter 1s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: newsCenter 1s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: newsCenter 1s ease-in-out forwards;
  /* Opera */
}

@keyframes newsCenter {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

@-webkit-keyframes newsCenter {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

.hospital-notice {
  position: relative;
  left: 100%;
  width: 28%;
  animation: newsCenter 1.5s ease-in-out forwards;
  -moz-animation: newsCenter 1.5s ease-in-out forwards;
  /* Firefox */
  -webkit-animation: newsCenter 1.5s ease-in-out forwards;
  /* Safari and Chrome */
  -o-animation: newsCenter 1.5s ease-in-out forwards;
  /* Opera */
}

.news-notice-bg {
  background-color: #f7f7f7;
  min-height: 350px;
  border: 1px solid #dddddd;
}

.news-notice-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #dddddd;
  padding: 20px 20px 0 20px;
}

.news-notice-a > a {
  color: #0d0d0d;
  text-decoration: none;
}

.news-notice-a > a:hover {
  color: #d46a4a;
  text-decoration: none;
}

.news-notice-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-notice-line {
  height: 5px;
  width: 90px;
  background-color: #d46a4a;
  margin-top: 10px;
  border-radius: 5px;
}

.news-notice-content {
  padding: 10px 20px;
}

.news-notice-item {
  padding: 20px 0;
  border-bottom: 2px dotted #dddddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-notice-item > a {
  color: #0d0d0d;
  text-decoration: none;
}

.news-notice-item > a:hover {
  color: #d46a4a;
  text-decoration: none;
}


@media screen and(max-width: 1450px) {
  .carousel-container {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  .news-notice-header {
    padding: 10px 10px 0 10px;
  }

  .news-notice-title {
    font-size: 18px;
  }

  .news-notice-line {
    width: 80px;
  }
}

@media screen and(max-width: 1070px) {
  .carousel-div {
    width: 30%;
  }
}

@media screen and(max-width: 850px) {
}

@media screen and(max-width: 680px) {
}

@media screen and (max-device-width: 430px) {

  .slider_img_min {
    height: 200px;
    width: 100%;
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .carousel-div {
    width: 100%;
    height: 200px;
  }

  .hospital-notice {
    width: 100%;
    margin-top: 10px;
  }

  @keyframes newsCenter {
    0% {
      left: 100%;
    }

    100% {
      left: 0%;
    }
  }

  @-webkit-keyframes newsCenter {
    0% {
      left: 100%;
    }

    100% {
      left: 0%;
    }
  }

  .carousel-div {
    position: relative;
    left: 100%;
    width: 100%;
    animation: newsCenter 1.5s ease-in-out forwards;
    -moz-animation: newsCenter 1.5s ease-in-out forwards;
    /* Firefox */
    -webkit-animation: newsCenter 1.5s ease-in-out forwards;
    /* Safari and Chrome */
    -o-animation: newsCenter 1.5s ease-in-out forwards;
    /* Opera */
  }

  .news-center {
    position: relative;
    left: 100%;
    width: 100%;
    margin-top: 10px;
    animation: newsCenter 2s ease-in-out forwards;
    -moz-animation: newsCenter 2s ease-in-out forwards;
    /* Firefox */
    -webkit-animation: newsCenter 2s ease-in-out forwards;
    /* Safari and Chrome */
    -o-animation: newsCenter 2s ease-in-out forwards;
    /* Opera */
  }
}
</style>
