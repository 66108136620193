<template>
  <div class="app-container">
    <!-- <div :class="bgContainer">
      <el-image :src="bgImage" class="bg-image"></el-image>
      <div class="bg-title">
        <div>专家介绍</div>
        <div class="bg-subtitle">EXPERT INTRODUCTION</div>
      </div>
    </div> -->
    <div class="home-container">
      <SelfBbreadcrumb :path="pathList"></SelfBbreadcrumb>
      <div class="doctor-info">
        <div class="doctor-all-info">
          <el-image v-if="!doctorInfo.avatar ||doctorInfo.avatar==null||doctorInfo.avatar==''"
                    :src="downImg.defaultAvatar" class="avatar-image">
          </el-image>
          <el-image v-else :src="doctorInfo.avatar" class="avatar-image"></el-image>
          <div class="doctor-all-in-info">
            <div class="doctor-basis-info">
              <div style="font-size: 18px;font-weight: bold;">
                {{ doctorInfo.doctorName }}
              </div>
              <div style="margin-left: 10px;">
                {{ doctorInfo.sex == "1" ? "男" : "女" }}
              </div>
              <div style="margin-left: 10px;">
                {{ doctorInfo.age }}岁
              </div>
            </div>
            <div class="doctor-rank-info">
              <div>
                {{ doctorInfo.deptName || "无" }}
              </div>
              <div style="margin-left: 10px;">
                {{ doctorInfo.rankName || "无" }}
              </div>
            </div>
          </div>
        </div>
        <div class="doctor-sub-info">
          <div>
            <span>简介：</span>{{ doctorInfo.introduction || "无" }}
          </div>
          <div style="margin-top: 10px;">
            <span>特长：</span>{{ doctorInfo.specialty || "无" }}
          </div>
        </div>
        <div style="text-align: center;">
          <img :src="qrCodeSrc" style="width: 300px;height: 300px;"/>
          <div style="font-size: 18px;font-weight: bold;">
            微信扫码/在微信浏览器中长按识别二维码咨询医生
          </div>
        </div>
        <el-dialog :visible.sync="open" width="50%" append-to-body>
          <div>
            <img :src="qrCodeSrc" alt="" style="width: 100%;height: 100%;"/>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from '@/assets/aboutYzy/d81ba9fa44f99c09.png'

// 引入qrcode库
import QRCode from 'qrcode'

export default {
  name: "",
  data() {
    return {
      bgImage,
      pathList: [{
        title: "专家介绍",
        path: "/experts"
      }, {
        title: "专家信息"
      }],
      doctorId: "",
      doctorInfo: {
        avatar: "",
        doctorName: "无",
        deptName: "无",
        rankName: "无",
        age: 0,
        sex: "1",
        introduction: "无",
        specialty: "无",
        doctorSwitch: "0"
      },
      downImg: {
        defaultAvatar: this.$downImage + 'icon_my_mrph.png'
      },
      qrCodeSrc: '',
      open: false
    }
  },
  computed: {
    // 计算高度
    bgContainer() {
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 430) {
        return 'bg-container';
      } else {
        return 'bg-container-phone';
      }
    },
  },
  created() {
    console.log(this.$route.query.doctorId);
    this.doctorId = this.$route.query.doctorId
    this.getDoctorInfo()
  },
  methods: {
    getDoctorInfo() {
      this.$axios.get(this.$https + `backend/official/doctor/?doctorId=${this.doctorId}`).then(res => {
        this.doctorInfo = res.data.data
        this.createQRCode()
      })
    },
    async createQRCode() {
      try {
        // 生成二维码
        let text = 'https://yzy.gzyzyy.cn/doctorIntroduction?doctorId=' + this.doctorId
        const img = await QRCode.toDataURL(text)
        this.qrCodeSrc = img
        console.log('....', img)
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.bg-container {
  height: 400px;
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.bg-image {
  width: 100%;
  height: 100%;
}

.bg-title {
  position: absolute;
  bottom: 15vh;
  right: 20vh;
  font-size: 45px;
  letter-spacing: 5px;
  font-weight: bold;
  color: #d46a4a;
  text-align: center;
}

.bg-subtitle {
  background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  letter-spacing: -2px;
}

.bg-subtitle:first-letter {
  font-size: 32px;
  font-weight: bold;
}

.bg-container-phone {
  position: relative;
  height: 180px;
  width: 90%;
  margin: 0 auto;
}

.home-container {
  width: 70%;
  margin: 0 auto;
}

.avatar-image {
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.doctor-info {
  padding: 20px;
}

.doctor-all-info {
  display: flex;
}

.doctor-all-in-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}

.doctor-basis-info {
  display: flex;
  align-items: center;
}

.doctor-rank-info {
  display: flex;
  align-items: center;
}

.doctor-sub-info {
  margin-top: 20px;
  text-align: justify;
  line-height: 30px;
  letter-spacing: 2px;
  text-indent: 2em;
}

@media screen and (max-device-width: 430px) {
  .home-container {
    width: 100%;
  }

  .bg-title {
    position: absolute;
    bottom: 6vh;
    right: 6vh;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #d46a4a;
    text-align: center;
  }

  .bg-subtitle {
    background-image: -webkit-linear-gradient(left, #d46a4a 10%, #f7f7f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .bg-subtitle:first-letter {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
